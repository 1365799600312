import React, { useEffect, useState } from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { getSamajMitra } from '../../actions/samajMitraAction'; // Replace with actual API call.
import { FormatFullDisplayName, searchResult } from '../../helpers/utils';
import { toast } from 'react-toastify';
import FormatDisplayName from '../matrimonials/componentUtils/formatDisplayName';
import { nodeDetails } from '../../actions/nodeDetailsAction';

const SelectSamajMitra = ({ setSamajMitra, samajMitraId, autoFocus = false }) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [firstTenOptions, setFirstTenOptions] = useState([]);
  const [samajMitraName, setSamajMitraName] = useState('');

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await getSamajMitra(); // Fetch volunteer data from your backend.
      console.log('response from get SamajmitraApi', res);
      const newData = res?.data?.map((samajMitra) => {
        const displayName = FormatFullDisplayName(
          samajMitra?.name,
          samajMitra?.father_name,
          samajMitra?.gender,
          samajMitra?.gautra,
          samajMitra?.subcaste,
          samajMitra?.village,
          samajMitra?.registered_mobile,
        );
        return {
          value: displayName,
          label: displayName, // Use formatted name for display
          graphNodeId: samajMitra?.graph_node_id,
        };
      });
      console.log('newData', newData);
      setOptions(newData);
      setFirstTenOptions(newData.slice(0, 10)); // Show the first 10 options initially.
      setLoading(false);
    } catch (e) {
      console.log('Could not fetch volunteers', e);
      setLoading(false);
      toast.error(`Could not fetch volunteers\n, Error:${e}`);
    }
  };
  const fetchSamajMitraDetails = async () => {
    try {
      console.log(`Fetching Samaj Mitra details for ID: ${samajMitraId}`);
      const response = await nodeDetails(samajMitraId);
      const displayName = FormatFullDisplayName(
        response?.basicProfile?.name,
        response?.basicProfile?.father_name,
        response?.basicProfile?.gender,
        response?.basicProfile?.gautra,
        response?.basicProfile?.subcaste,
        response?.basicProfile?.village,
        response?.basicProfile?.registered_mobile,
      );
      setSamajMitraName(displayName);
    } catch (error) {
      console.error('Error fetching Samaj Mitra details:', error);
    }
  };

  useEffect(() => {
    fetchData();
    if (samajMitraId) {
      fetchSamajMitraDetails();
    }
  }, []);

  const searchOptions = (inputValue) => {
    return searchResult(options, inputValue.trim());
  };

  let win_variable = '';
  if (typeof document !== 'undefined') {
    win_variable = document.body;
  }
  const handleClearValue = () => {
    console.log('handle clear clicked');
    toast.success('Samaj mitra role removed successfully');
    setSamajMitraName('');
    setSamajMitra(null);
  };
  return (
    <AsyncCreatableSelect
      onChange={(option) => {
        if (option) {
          const confirmSelection = window.confirm(`Are you sure you want to select ${option.label} for Samaj mitra?`);

          if (confirmSelection) {
            setSamajMitraName(option.label);
            setSamajMitra(option);
          }
        } else {
          handleClearValue();
        }
      }}
      // if (option) {
      //   if (setSamajMitraId) {
      //    // Pass the graphNodeId of selected volunteer
      //   }
      //   setSamajMitra(option?.value.trim());
      // } else {
      //   // Handle clearing the selection
      //   setSamajMitra('');
      //   if (setSamajMitraId) {
      //     setSamajMitraId(null); // Reset graphNodeId when cleared
      //   }
      // }
      // defaultValue={samajMitra ? { label: samajMitra, value: samajMitra } : null}
      value={samajMitraName ? { label: samajMitraName, value: samajMitraName } : null}
      isLoading={isLoading}
      autoFocus={autoFocus}
      cacheOptions
      defaultOptions={firstTenOptions}
      loadOptions={searchOptions}
      placeholder="Select Samaj Mitra"
      isClearable
      onClear={handleClearValue}
      maxMenuHeight={150}
      menuPlacement="bottom"
      menuPortalTarget={win_variable ?? <></>}
      styles={{
        option: (style) => ({ ...style, color: 'black' }),
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        control: (provided) => ({
          ...provided,
          // minHeight: '40px', // Ensures enough space for wrapping
          width: '460px',
        }),
      }}
    />
  );
};

export default SelectSamajMitra;
